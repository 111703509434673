define("discourse/plugins/discourse-chat-integration/discourse/public-route-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default() {
    this.route("transcript", {
      path: "/chat-transcript/:secret"
    });
  }
});